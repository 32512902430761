<template>
  <div
    class="d-flex flex-wrap table-footer-count pt-1"
    v-if="countKeys && countKeys.length > 0"
  >
    <div class="mr-2" v-for="key in countKeys" :key="`k_${key}`">
      {{ $t(`labels.${key}`) }}:
      <b>{{ formatNumber(counts[key]) }}</b>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReceiptHistoryCount",
  props: {
    counts: {
      type: [Array, Object],
      default: () => {},
    },
  },
  computed: {
    countKeys() {
      return Object.keys(this.counts);
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
